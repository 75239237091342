import React, { useCallback, useRef } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import Card from "react-bootstrap/Card";
import Form, { FormProps } from "react-bootstrap/Form";

import type { Capability } from "api/__generated__/CapabilitySetCreate_createCapabilitySet_Mutation.graphql";
import CheckboxTree, { Tree } from "components/CheckboxTree";
import Stack from "components/Stack";

type FormData = {
  name: string;
  capabilities: Capability[];
};

const capabilityTrees: Tree<Capability>[] = [
  {
    value: "CAN_MANAGE_APPLIANCE_MODELS",
    label: (
      <FormattedMessage
        id="components.CapabilitySetForm.canManageApplianceModelsCapability"
        defaultMessage="Can manage Appliance Models"
        description="Label for the permission to manage appliance models"
      />
    ),
  },
  {
    value: "CAN_ADMIN_APPLIANCES",
    label: (
      <FormattedMessage
        id="components.CapabilitySetForm.canAdminAppliancesCapability"
        defaultMessage="Can administrate Appliances"
        description="Label for the permission to administrate appliances"
      />
    ),
  },
  {
    value: "CAN_MANAGE_SERVICE_ACTIVATIONS",
    label: (
      <FormattedMessage
        id="components.CapabilitySetForm.canManageServiceActivationsCapability"
        defaultMessage="Can manage activations of Services"
        description="Label for the permission to manage services activations"
      />
    ),
  },
];

type Props = Omit<FormProps, "onChange"> & {
  readOnly?: boolean;
  value: FormData;
  onChange?: (data: FormData, isValid: boolean) => void;
};

const CapabilitySetForm = ({
  readOnly = false,
  value,
  onChange,
  ...props
}: Props) => {
  const formRef = useRef<HTMLFormElement>(null);
  const intl = useIntl();

  const handleInputChange: React.ChangeEventHandler<HTMLInputElement> =
    useCallback(
      (event) => {
        const target = event.target;
        const fieldValue =
          target.type === "checkbox" ? target.checked : target.value;
        const field = target.id;
        const newValue = { ...value, [field]: fieldValue };
        const isFormValid = !!formRef.current?.checkValidity();
        onChange && onChange(newValue, isFormValid);
      },
      [value, onChange]
    );

  const handleCapabilitiesChange = useCallback(
    (capabilities: Capability[]) => {
      const newValue = { ...value, capabilities };
      const isFormValid = !!formRef.current?.checkValidity();
      onChange && onChange(newValue, isFormValid);
    },
    [value, onChange]
  );

  return (
    <Form {...props} ref={formRef} data-testid="capability-set-form">
      <Stack gap={3}>
        <Form.Group controlId="name">
          <Form.Label>
            <FormattedMessage
              id="components.CapabilitySetForm.nameLabel"
              defaultMessage="Name"
              description="Label for the name field in the capability set form"
            />
          </Form.Label>
          <Form.Control
            value={value.name}
            onChange={handleInputChange}
            readOnly={readOnly}
            plaintext={readOnly}
            required
            placeholder={intl.formatMessage({
              id: "components.CapabilitySetForm.namePlaceholder",
              defaultMessage: "Type in the capability set name",
              description:
                "Placeholder for the name field in the capability set form",
            })}
            name="name"
            data-testid="capability-set-form-name"
          />
        </Form.Group>
        <Form.Group
          controlId="capabilities"
          data-testid="capability-set-form-capabilities"
        >
          <Form.Label>
            <FormattedMessage
              id="components.CapabilitySetForm.capabilitiesLabel"
              defaultMessage="Capabilities"
              description="Label for the capabilities field in the capability set form"
            />
          </Form.Label>
          <Card className="border-0 shadow-sm p-4">
            <CheckboxTree
              trees={capabilityTrees}
              checked={value.capabilities}
              onChange={handleCapabilitiesChange}
              readOnly={readOnly}
              alwaysExpanded
            />
          </Card>
        </Form.Group>
      </Stack>
    </Form>
  );
};

export default CapabilitySetForm;
